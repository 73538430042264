import normalizeEntity from 'normalized-redux/entity-normalizer';

const rpcs = [
  'addParticipant',
  'saveAgreement',
  'moveAgreement',
  'cancelAgreement',
  'declineAgreement',
  'signAgreement',
  'sendSignCode',
  'publishAgreement',
  'messageParticipants',
  'scheduleInternalReminder',
  'reScheduleInternalReminder',
  'removeInternalReminder',
  'terminateAgreement',
  'requestAuthenticationToken',
  'validateAuthenticationToken',
  'updateContractValue',
  'updateContractName',
  'updateExpiryDate',
  'changeTemplateGroup',
  'updateAgreementPreferences',
  'updateAttachmentBox',
  'sendConcludedAgreementCopy',
  'updateSignOrder',
  'updateConfig',
  'fetchContractLinks',
  'fetchAiReviews',
  'fetchAiImportData',
  'fetchContractInternalReminders',
  'replaceAgreement',
  'postMessage',
  'updateParticipant',
  'removeParticipant',
  'updateParty',
  'removeParty',
  'toggleNotificationsGuest',
  'approveDraft',
  'approvePendingState',
  'fetchParticipantsSignState',
  'restoreTrashedAgreement',
  'permanentDeleteAgreement',
  'emptyAgreementTrash',
] as const;

export default normalizeEntity<Oneflow.Agreement, 'agreement', typeof rpcs>({
  entity: 'agreement',
  rpcs,
});
