import { Message } from '@oneflowab/pomes';
import { get } from 'lodash';

import ErrorBox from 'components/error-box';
import type { ErrorMessage } from 'components/error-box';
import { HelpCenterLink } from 'components/help-center-link';

type ErrorCode = number | string | undefined;

type Props = {
  customMessage?: ErrorMessage,
  errorCode?: ErrorCode,
};

export const getErrorCode = (error: any) => get(error, 'body.errors[0].error_details.error_code');

export const getVideoErrorMessages = () => {
  const headerText = (
    <Message
      id="Processing failed"
      comment="Notification header about failure while encoding a video"
    />
  );
  const videoSupportPagePath = 'support/solutions/articles/77000435946-adding-a-video-to-contracts';

  return ({
    unknownError: {
      headerText,
      bodyText: (
        <Message
          id="An error has occurred. Please reload the page or try again later."
          comment="Notification about failure while encoding a video"
        />
      ),
    },
    timeoutError: {
      headerText,
      bodyText: (
        <>
          <Message
            id="It took too long to process your video, please try again."
            comment="Notification about failure while encoding a video"
          />
          {' '}
          <HelpCenterLink path={videoSupportPagePath} />
        </>
      ),
    },
    invalidInputError: {
      headerText,
      bodyText: (
        <Message
          id="Your video file is not supported. Try again with a different file."
          comment="Notification about failure while encoding a video"
        />
      ),
    },
    invalidResolutionError: {
      headerText,
      bodyText: (
        <>
          <Message
            id="The video resolution is not supported."
            comment="Notification about failure while encoding a video"
          />
          {' '}
          <HelpCenterLink path={videoSupportPagePath} />
        </>
      ),
    },
    codecNotSupportedError: {
      headerText,
      bodyText: (
        <>
          <Message
            id="Your video file is not supported."
            comment="Notification about failure while encoding a video"
          />
          {' '}
          <HelpCenterLink path={videoSupportPagePath} />
        </>
      ),
    },
  });
};

export const unknownApiError = {
  headerText: (
    <Message
      id="It should be flawless, but it's not..."
      comment="Notification header about failure when an unknown error happens"
    />
  ),
  bodyText: (
    <Message
      id="An internal error has occurred. Please reload the page or try again later."
      comment="Notification about failure when an unknown error happens"
    />
  ),
};

export const getErrorMessages = () => ({
  accountLimitExceeded: {
    headerText: (
      <Message
        id="Account limit exceeded"
        comment="Notification header about failure when account limit exceeded"
      />
    ),
    bodyText: (
      <Message
        id="Please contact support at {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Notification about failure when account limit exceeded"
      />
    ),
  },
  documentPagesExceeded: {
    headerText: (
      <Message
        id="An error occurred while uploading the file."
        comment="Notification header about failure when an error occurred while uploading the file."
      />
    ),
    bodyText: (
      <Message
        id="PDF contains too many pages (max 200)."
        comment="Notification about failure when PDF contains too many pages (max 200)"
      />
    ),
  },
  fileLimitExceeded: {
    headerText: (
      <Message
        id="Couldn't perform this action"
        comment="Notification header about failure when file limit exceeded"
      />
    ),
    bodyText: (
      <Message
        id="You've exceeded the allowed number of attachments or their total size."
        comment="Notification about failure when file limit exceeded"
      />
    ),
  },
  fileExtensionInvalid: {
    headerText: (
      <Message
        id="Couldn't perform this action"
        comment="Notification header about failure when file extension is not accepted"
      />
    ),
    bodyText: (
      <Message
        id="The selected file type is not supported. Please select another file."
        comment="Notification about upload failure when file type is not valid"
      />
    ),
  },
  extensionDisabled: {
    headerText: (
      <Message
        id="Couldn't perform this action"
        comment="Notification header about failure when a used extension is disabled"
      />
    ),
    bodyText: (
      <Message
        id="You are using an extension that has been disabled in your account."
        comment="Notification about failure when a used extension is disabled"
      />
    ),
  },
  emailTaken: {
    headerText: (
      <Message
        id="Email address already registered"
        comment="Error header when email is taken."
      />
    ),
    bodyText: (
      <Message
        id="Please contact support at {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Error body text when email is taken."
      />
    ),
  },
  invalidCredentials: {
    headerText: (
      <Message
        id="Invalid email/password"
        comment="Error header when invalid email/password."
      />
    ),
    bodyText: (
      <Message
        id="Please try again."
        comment="Error body text when invalid email/password."
      />
    ),
  },
  invalidPassword: {
    headerText: (
      <Message
        id="Invalid password"
        comment="Error header when password is invalid."
      />
    ),
    bodyText: (
      <Message
        id="Please try again."
        comment="Error body text when password is invalid."
      />
    ),
  },
  userInvitelimitExceeded: {
    headerText: (
      <Message
        id="User invite limit exceeded. Please try again later."
        comment="Notification header about failure when user invite limit exceeded"
      />
    ),
    bodyText: (
      <Message
        id="Please contact support at {email} to upgrade the plan."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Notification about failure when user invite limit exceeded"
      />
    ),
  },
  invalidCredentialsSecurityCode: {
    headerText: (
      <Message
        id="Invalid security code"
        comment="Error header when invalid security code."
      />
    ),
    bodyText: (
      <Message
        id="Please try again."
        comment="Error body text when invalid security code."
      />
    ),
  },
  invalidToken: {
    headerText: (
      <Message
        id="Invalid sign up key"
        comment="Error header when invalid signup token."
      />
    ),
    bodyText: (
      <Message
        id="Your sign up key is not valid. It could be because it has expired or because a new sign up key was requested for the same email address. Submit your email again below and we will send you a new key!"
        comment="Error body text when invalid signup token."
      />
    ),
  },
  locked: {
    headerText: (
      <Message
        id="Your account has been locked"
        comment="Error header when account is locked."
      />
    ),
    bodyText: (
      <Message
        id="Please contact support at {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Error body text when account is locked."
      />
    ),
  },
  ssoActive: {
    headerText: (
      <Message
        id="SSO domain is active on this email"
        comment="Error header when SSO is active."
      />
    ),
    bodyText: (
      <Message
        id="Please contact your administrator."
        comment="Error body text when SSO is active."
      />
    ),
  },
  trialEnded: {
    headerText: (
      <Message
        id="Your free trial has expired"
        comment="Error header when trial has expired."
      />
    ),
    bodyText: (
      <Message
        id="Please contact us on {email} or {phoneNumber}."
        values={{
          email: <a href="mailto:sales@oneflow.com">sales@oneflow.com</a>,
          phoneNumber: <a href="tel:+46851729770">+46 8-517 297 70</a>,
        }}
        comment="Error body text when trial has expired."
      />
    ),
  },
  userIsAlreadyRegistered: {
    headerText: (
      <Message
        id="User is already registered"
        comment="Error header when user is already registered."
      />
    ),
    bodyText: (
      <Message
        id="Please contact support at {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Error body text when user is already registered."
      />
    ),
  },
  userSamePassword: {
    headerText: (
      <Message
        id="The new password is not allowed to be the same as the previous one"
        comment="Error header when user is trying to use the same password as before."
      />
    ),
    bodyText: (
      <Message
        id="Please use another password."
        comment="Error body text when user is trying to use the same password as before."
      />
    ),
  },
  invalidCode: {
    headerText: (
      <Message
        id="Security code is incorrect or expired"
        comment="Error header when the security code for 2-factor authentication method is incorrect or has expired."
      />
    ),
    bodyText: (
      <Message
        id="Please try again or request a new code."
        comment="Error body when the security code for 2-factor authentication method is incorrect or has expired."
      />
    ),
  },
  invalidCodePersonalIdentification: {
    headerText: (
      <Message
        id="The number doesn't match the personal ID in the document. Please check for a typo or change the format."
        comment="Error when the personal identification for 2-factor authentication method is incorrect."
      />
    ),
  },
  partiesNotAccepted: {
    headerText: (
      <Message
        id="The participant list is not accepted"
        comment="Error header when copying a contract that has invalid parties."
      />
    ),
    bodyText: (
      <Message
        id="Please contact support at {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Error body when copying a contract that has invalid parties."
      />
    ),
  },
  contractNotReady: {
    headerText: (
      <Message
        id="The contract is not ready"
        comment="Error header when copying a contract that is not ready."
      />
    ),
    bodyText: (
      <Message
        id="Please contact support at {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Error body when copying a contract that is not ready."
      />
    ),
  },
  invalidCaptcha: {
    headerText: (
      <Message
        id="Invalid captcha"
        comment="Error header when the captcha code is incorrect or has expired."
      />
    ),
    bodyText: (
      <Message
        id="Please try again."
        comment="Error body when the captcha code is incorrect or has expired."
      />
    ),
  },
  signingInProgress: {
    headerText: (
      <Message
        id="Someone else is signing now"
        comment="Error header when the contract is being signed by another party at this moment."
      />
    ),
    bodyText: (
      <Message
        id="The contract is being signed by someone else right now, please wait a moment and try again."
        comment="Error body when the contract is being signed by another party at this moment."
      />
    ),
  },
  checksumMismatch: {
    headerText: (
      <Message
        id="The contract version is not up to date"
        comment="Error header when the contract is not the latest revision available."
      />
    ),
    bodyText: (
      <Message
        id="To perform this action, please make sure to refresh the page to get the latest version."
        comment="Error body when the contract is not the latest revision available."
      />
    ),
  },
  activeSuggestions: {
    headerText: (
      <Message
        id="This contract can't be signed"
        comment="Error header when the contract includes active suggestions."
      />
    ),
    bodyText: (
      <Message
        id="To sign the document, please review and resolve active suggestions."
        comment="Error body when the contract includes active suggestions."
      />
    ),
  },
  emptyRequiredFields: {
    headerText: (
      <Message
        id="This contract can't be signed"
        comment="Error header when the contract includes empty fields that are required."
      />
    ),
    bodyText: (
      <Message
        id="Please fill in all required fields."
        comment="Error body when the contract includes empty fields that are required."
      />
    ),
  },
  invalidContractState: {
    headerText: (
      <Message
        id="This contract can't be signed"
        comment="Error header when the contract is in a state that does not allow signing."
      />
    ),
    bodyText: (
      <Message
        id="The contract couldn't be signed because it's in a state that doesn't allow signing, please reload the contract."
        comment="Error body when the contract is in a state that does not allow signing."
      />
    ),
  },
  alreadySigned: {
    headerText: (
      <Message
        id="This contract can't be signed"
        comment="Error header when the contract has been already signed."
      />
    ),
    bodyText: (
      <Message
        id="You have already signed this contract"
        comment="Error body when the contract has been already signed."
      />
    ),
  },
  invalidAccessMethod: {
    headerText: (
      <Message
        id="This contract can't be signed"
        comment="Error header when the contract cannot be signed due to invalid access method."
      />
    ),
    bodyText: (
      <Message
        id="Your current method for accessing the contract doesn't allow the used sign method"
        comment="Error body when the contract cannot be signed due to invalid access method."
      />
    ),
  },
  singleParticipantSignOrder: {
    headerText: (
      <Message
        id="Signing order requires more than one participant"
        comment="Error header when trying to send a document with a single participant and signing order enabled."
      />
    ),
    bodyText: (
      <Message
        id="To continue, add more participants or turn off signing order."
        comment="Error body when trying to send a document with a single participant and signing order enabled."
      />
    ),
  },
  invalidStartEndDate: {
    headerText: (
      <Message
        id="This contract can't be signed"
        comment="Error header when the contract has a start date after end date."
      />
    ),
    bodyText: (
      <Message
        id="The end date of the contract must be after the expected start date."
        comment="Error body when the contract has a start date after end date."
      />
    ),
  },
  alreadyInProgress: {
    headerText: (
      <Message
        id="Couldn't perform this action"
        comment="Error message header when signing with BankId failed"
      />
    ),
    bodyText: (
      <Message
        id="Action already in progress. Please try again."
        comment="Error returned by the BankID."
      />
    ),
  },
  assetProcessingFailed: {
    headerText: (
      <Message
        id="Action failed due to file error."
        comment="Message to show action failed due to file error."
      />
    ),
    bodyText: (
      <Message
        id="Please fix the file in the document."
        comment="Message to explain user to fix the file in the document."
      />
    ),
  },
  assetProcessing: {
    headerText: (
      <Message
        id="Processing in progress."
        comment="Message to show action failed due to file processing."
      />
    ),
    bodyText: (
      <Message
        id="Please try again shortly."
        comment="Message to show template needs a fix before using it."
      />
    ),
  },
  expiredTransaction: {
    headerText: (
      <Message
        id="Signing expired"
        comment="Error message header when signing with BankId failed"
      />
    ),
    bodyText: (
      <Message
        id="The BankID app isn't responding. Please check that the program is started and that you have internet access. If you don’t have a valid BankID you can get one from your bank. Try again."
        comment="Error returned by the BankID."
      />
    ),
  },
  unknownBankId: {
    headerText: (
      <Message
        id="Please try again."
        comment="Error message header when signing with BankId failed"
      />
    ),
    bodyText: (
      <Message
        id="Unknown error. Please try again."
        comment="Error returned by the BankID."
      />
    ),
  },
  actionCancelled: {
    headerText: (
      <Message
        id="Action canceled."
        comment="Error message header when signing with BankId failed"
      />
    ),
    bodyText: (
      <Message
        id="Action canceled. Please try again."
        comment="RFA3 error message from BankID specification."
      />
    ),
  },
  certificateError: {
    headerText: (
      <Message
        id="Action canceled."
        comment="Error message header when signing with BankId failed"
      />
    ),
    bodyText: (
      <Message
        id="The BankID you are trying to use is revoked or too old. Please use another BankID or order a new one from your internet bank."
        comment="RRFA16FA3 error message from BankID specification."
      />
    ),
  },
  userCancelled: {
    headerText: (
      <Message
        id="Please try again."
        comment="Error message header when signing with BankId failed"
      />
    ),
    bodyText: (
      <Message
        id="BankID signature was canceled. Please try again."
        comment="RFA5 error message from BankID specification"
      />
    ),
  },
  internalError: {
    headerText: (
      <Message
        id="An error has occurred. Please try again."
        comment="Error message header when signing with BankId failed"
      />
    ),
    bodyText: (
      <Message
        id="An error has occurred in the BankID system."
        comment="FA17 error message from BankID specification"
      />
    ),
  },
  maintenance: {
    headerText: (
      <Message
        id="Please try again."
        comment="Error message header when signing with BankId failed"
      />
    ),
    bodyText: (
      <Message
        id="The BankID service is temporarily out of service."
        comment="FA17 error message from BankID specification"
      />
    ),
  },
  notFound: {
    headerText: (
      <Message
        id="An error has occurred. Please try again."
        comment="Error message header when signing with BankId failed"
      />
    ),
    bodyText: (
      <Message
        id="An error has occurred. Please contact support@oneflow.com."
        comment="RFA5 error message from BankID specification"
      />
    ),
  },
  startFailed: {
    headerText: (
      <Message
        id="Please try again."
        comment="Error message header when signing with BankId failed"
      />
    ),
    bodyText: (
      <Message
        id="The BankID app couldn’t be found on your computer or mobile device. Please install it and order a BankID from your internet bank. Install the app from install.bankid.com."
        comment="FA17 error message from BankID specification"
      />
    ),
  },
  requestTimeout: {
    headerText: (
      <Message
        id="Please try again."
        comment="Error message from BankID specification"
      />
    ),
    bodyText: (
      <Message
        id="Your request timed out. Please try again."
        comment="RFA5 error message from BankID specification"
      />
    ),
  },
  participantEmailTaken: {
    headerText: (
      <Message
        id="Please try again."
        comment="A message for when user tries to use an email that is taken"
      />
    ),
    bodyText: (
      <Message
        id="A person with that phone number or email address already exists in the contract"
        comment="A message for when user tries to use an email that is taken"
      />
    ),
  },
  eidGeneralError: {
    headerText: (
      <Message
        id="Please try again."
        comment="Header for general error message header when signing with eID failed"
      />
    ),
    bodyText: (
      <Message
        id="Something went wrong. Please reload the page and try again."
        comment="General error message header when signing with eID failed"
      />
    ),
  },
  eidSignicatOutcomeAbort: {
    headerText: (
      <Message
        id="Signing aborted."
        comment="Header for error message when eID signing is aborted by the user"
      />
    ),
    bodyText: (
      <Message
        id="Signing with electronic ID aborted by the user. No signature was made."
        comment="Error message when signing is aborted by the user"
      />
    ),
  },
  createAccountBadRequest: {
    headerText: (
      <Message
        id="Something went wrong."
        comment="Header for error message when create account request fails"
      />
    ),
    bodyText: (
      <Message
        id="Please contact support at {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Notification about failure when create account fails"
      />
    ),
  },
  contentLimitExceeded: {
    headerText: (
      <Message
        id="Content limit exceeded."
        comment="Header for error message there is too much content in a single document section"
      />
    ),
    bodyText: (
      <Message
        id="It looks like one of your document sections contains more content than we can process right now. Try splitting large sections. For more help, please contact support at {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Error message when there is too much content in a single document section"
      />
    ),
  },
  noProductTableColumns: {
    headerText: (
      <Message
        id="Box configuration is invalid."
        comment="Header for error message when a the product table box configuration is invalid"
      />
    ),
    bodyText: (
      <Message
        id="There needs to be at least one column in the product table."
        comment="Text to instruct on how to fix the error message when a the product table box configuration is invalid"
      />
    ),
  },
  productSumLimitExceeded: {
    headerText: (
      <Message
        id="Product total exceeds the limit."
        comment="Header for error message when the total product sum exceeds the allowed limit"
      />
    ),
    bodyText: (
      <Message
        id="The combined value of your product tables has surpassed the maximum limit of 1 trillion. Please adjust the quantity or prices to proceed."
        comment="Error message displayed when the total product sum surpasses one trillion."
      />
    ),
  },
});

export const getVideoErrorMessage = (errorCode: ErrorCode): ErrorMessage => {
  const videoErrors = getVideoErrorMessages();

  switch (errorCode) {
    case 11000:
      return videoErrors.unknownError;
    case 11001:
      return videoErrors.timeoutError;
    case 11002:
      return videoErrors.invalidInputError;
    case 11003:
      return videoErrors.invalidResolutionError;
    case 11004:
      return videoErrors.codecNotSupportedError;
    default:
      return videoErrors.unknownError;
  }
};

export const getErrorMessage = (errorCode: ErrorCode): ErrorMessage | undefined => {
  const apiErrors = getErrorMessages();

  switch (errorCode) {
    case 101:
      return apiErrors.accountLimitExceeded;
    case 1000:
      return apiErrors.invalidCredentials;
    case 1001:
      return apiErrors.userIsAlreadyRegistered;
    case 1003:
      return apiErrors.invalidCredentials;
    case 1005:
      return apiErrors.ssoActive;
    case 1007:
      return apiErrors.emailTaken;
    case 1008:
      return apiErrors.invalidCredentialsSecurityCode;
    case 1011:
      return apiErrors.userSamePassword;
    case 1012:
      return apiErrors.invalidCaptcha;
    case 1013:
      return apiErrors.invalidPassword;
    case 1014:
      return apiErrors.userInvitelimitExceeded;
    case 2001:
      return apiErrors.locked;
    case 2002:
      return apiErrors.trialEnded;
    case 4001:
      return apiErrors.signingInProgress;
    case 4002:
      return apiErrors.checksumMismatch;
    case 4003:
      return apiErrors.invalidContractState;
    case 4005:
      return apiErrors.alreadySigned;
    case 4007:
      return apiErrors.emptyRequiredFields;
    case 4009:
      return apiErrors.invalidAccessMethod;
    case 4013:
      return apiErrors.invalidStartEndDate;
    case 4054:
      return apiErrors.singleParticipantSignOrder;
    case 4101:
      return apiErrors.participantEmailTaken;
    case 4109:
      return apiErrors.invalidCode;
    case 4110:
      return apiErrors.partiesNotAccepted;
    case 4111:
      return apiErrors.invalidCodePersonalIdentification;
    case 4204:
      return apiErrors.activeSuggestions;
    case 4405:
      return apiErrors.productSumLimitExceeded;
    case 4409:
      return apiErrors.noProductTableColumns;
    case 4502:
      return apiErrors.fileExtensionInvalid;
    case 4505:
      return apiErrors.documentPagesExceeded;
    case 4506:
      return apiErrors.fileLimitExceeded;
    case 4998:
      return apiErrors.contractNotReady;
    case 6001:
      return apiErrors.extensionDisabled;
    case 6007:
      return apiErrors.alreadyInProgress;
    case 'ASSET_PROCESSING':
      return apiErrors.assetProcessing;
    case 'ASSET_PROCESSING_FAILED':
      return apiErrors.assetProcessingFailed;
    case 'CANCELLED':
      return apiErrors.actionCancelled;
    case 'CERTIFICATE_ERR':
      return apiErrors.certificateError;
    case 'CONTENT_LIMIT_EXCEEDED':
      return apiErrors.contentLimitExceeded;
    case 'EID_CLIENT_ERROR':
      return apiErrors.eidGeneralError;
    case 'EID_COLLECT_ERROR':
      return apiErrors.eidGeneralError;
    case 'EID_SIGN_ERROR':
      return apiErrors.eidGeneralError;
    case 'EID_SIGNICAT_OUTCOME_ABORT':
      return apiErrors.eidSignicatOutcomeAbort;
    case 'EXPIRED_TRANSACTION':
      return apiErrors.expiredTransaction;
    case 'INTERNAL_ERROR':
      return apiErrors.internalError;
    case 'MAINTENANCE':
      return apiErrors.maintenance;
    case 'NOT_FOUND':
      return apiErrors.notFound;
    case 'REQUEST_TIMEOUT':
      return apiErrors.requestTimeout;
    case 'START_FAILED':
      return apiErrors.startFailed;
    case 'UNKNOWN':
      return apiErrors.unknownBankId;
    case 'USER_CANCEL':
      return apiErrors.userCancelled;
    default:
      return undefined;
  }
};

export const ApiError = (props: Props) => {
  const { errorCode, customMessage } = props;

  if (customMessage) {
    return (
      <ErrorBox
        bodyText={customMessage.bodyText}
        hasErrorStyle
        hasInfoStyle={customMessage.hasInfoStyle}
        headerText={customMessage.headerText}
      />
    );
  }

  if (!errorCode) {
    return null;
  }

  const apiError = getErrorMessage(errorCode);
  if (!apiError) {
    return null;
  }

  return (
    <ErrorBox
      bodyText={apiError.bodyText}
      hasErrorStyle
      hasInfoStyle={apiError.hasInfoStyle}
      headerText={apiError.headerText}
    />
  );
};
