import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { isEnvironment } from 'utils/environment';

import Public from './public';
import '../styles/base.scss';
import '../styles/elements.scss';
import '../styles/fonts.scss';

if (!isEnvironment('development')) {
  import('polyfills');

  import('logging').then(({ rollbar }) => {
    rollbar.init();
  });
}

const root = createRoot(document.getElementById('app'));
root.render(React.createElement(Public));
