/* eslint-disable import/named */
import {
  get,
  post,
  extractResponseBodyAsJSON,
  camelize,
} from 'oneflow-client/core';

export const aiExtractAgreement = (agreementId: number) => (
  post({
    url: `/ext/ai_import/agreements/${agreementId}/enrich`,
  })
    .then(extractResponseBodyAsJSON)
    .then(camelize));

export const fetchExtractedData = ({ id }: { id: number }) => (
  get({
    url: `/ext/ai_import/agreements/${id}/highlight`,
  })
    .then(extractResponseBodyAsJSON));
